$primary: #409EFF;
$bg: #f2f0f7;

$almost-black: #020d16;
$dark: #091520;
$dark-100: #0e243a;
$dark-200: #2f455a;
$dark-300: #5a738a;
$dark-500: #92a0bd;

$danger: #d23131;

$darker: rgba(0, 0, 0, 0.1);
$lighter: rgba(255, 255, 255, 0.1);