#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 99;
  padding: 20px 20px 20px 90px;
  box-sizing: border-box;
  border-bottom: 1px solid $darker;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height: 35px;
  }

  .left {
    display: flex;
    align-items: center;
    .search {
      display: flex;
      align-items: center;
      margin-left: 150px;
      i {
        line-height: 40px;
        font-size: 22px;
        color: #999;
      }
      input {
        width: 200px;
        height: 40px;
        line-height: 40px;
        padding: 0 0 0 20px;
        font-size: 15px;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    .quick-action {
      margin-right: 100px;
      li {
        display: inline-block;
        line-height: 40px;
        font-size: 24px;
        padding: 0 5px;
        margin: 0 5px;
        cursor: pointer;
      }
    }
    .profile {
      display: flex;
      padding: 5px;
      border-radius: 999px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all ease .2s;
      span {
        display: inline-block;
        line-height: 30px;
        padding: 0 20px;
        font-size: 15px;
      }
      .avatar {
        height: 30px;
      }
      &:hover {
        background-color: $darker;
        transition: all ease .2s;
      }
    }
    .dark-mode-button {
      font-size: 30px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

#nav {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-info {
    flex-grow: 1;
    .title {
      margin-bottom: 15px;
      h1 {
        margin: 0;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        margin-right: 15px;
        text-transform: uppercase;
      }
      h2 {
        margin: 0;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}

.theme-dark {
  #header {
    background-color: $almost-black;
    color: #fff;
    .left .search i, .right .profile span {
      color: #fff;
    }
  }

  #nav {
    color: #fff;
  }

  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #dadada;
  }
}