$--color-primary: $primary;
$--font-path: '../fonts';

@import '~element-ui/packages/theme-chalk/src/index';

.error .el-input__inner {
  border-color: $danger;
}

.form-group .error .el-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: $danger;
}

.el-select,
.el-range-editor,
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.theme-dark {
  .el-button {
    background-color: $dark-100;
    color: #fff;
    border-color: transparent;
    &:hover {
      background-color: $dark-200;
    }
  }
}

.el-drawer__wrapper {
  .el-drawer__header {
    font-size: 20px;
  }

  .el-drawer__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 76px);
    .content {
      padding: 20px;
      overflow-y: auto;
      .el-form {
        flex-grow: 1;
      }
    }
  }

  .drawer__footer {
    display: flex;
    padding-top: 20px;
    padding: 20px;
    .button {
      width: 100%;
    }
  }
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  color: #909399;
  font-size: 14px;
  &.with-icon {
    font-size: 22px;
  }
}

.el-textarea {
  .el-textarea__inner {
    min-height: 39px;
    height: 39px;
    box-sizing: border-box;
    padding: 8px 15px;
  }

  .el-input__count {
    height: 37px;
    line-height: 38px;
    bottom: 1px;
  }
}
