.post,
.offer {
  .images {
    width: 100%;
    display: flex;
    height: 500px;
    margin-top: 15px;
    .featured-image {
      width: 100%;
      height: 100%;
      flex-shrink: 2;
      background-color: #f5f7fa;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .extra-images {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 15px;
      &.images-1 {
        width: 33.33%;
        .extra-image {
          height: 100%;
        }
      }
      &.images-2 {
        width: 250px;
        .extra-image {
          height: calc((500px - 15px) / 2);
        }
      }
      &.images-3 {
        width: calc(500px / 3);
        .extra-image {
          height: calc((500px - 30px) / 3);
        }
      }
      &.images-4 {
        width: 500px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .extra-image {
          width: calc((500px - 15px) / 2);
          height: calc((500px - 15px) / 2);
        }
      }
      .extra-image {
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
    .featured-image img,
    .extra-images .extra-image img {
      transition: all ease 0.15s;
      &:hover {
        outline: $primary solid 3px;
      }
    }
  }
  .description {
    h3 {
      font-size: 15px;
      color: $dark;
    }
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.7;
    color: $dark-300;
  }
}
