.navigation {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      a {
        display: block;
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        color: #0e243a;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
        &.router-link-exact-active {
          color: #409EFF;
          background-color: transparent;
        }
      }
      &:first-of-type a {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}

.theme-dark .navigation {
  background-color: $dark-100;
}